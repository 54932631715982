// Dependent colors
$black: #000000;
$white: #ffffff;

$classic-base-color: #282c37;
$classic-primary-color: #9baec8;
$classic-secondary-color: #d9e1e8;
$classic-highlight-color: #6364ff;

$blurple-600: #563acc; // Iris
$blurple-500: #6364ff; // Brand purple
$blurple-300: #858afa; // Faded Blue
$grey-600: #4e4c5a; // Trout
$grey-100: #dadaf3; // Topaz

// Differences
$success-green: lighten(#3c754d, 8%);

$base-overlay-background: $white !default;
$valid-value-color: $success-green !default;

$ui-base-color: $classic-secondary-color !default;
$ui-base-lighter-color: #b0c0cf;
$ui-primary-color: $classic-primary-color !default;
$ui-secondary-color: $classic-base-color !default;
$ui-highlight-color: $classic-highlight-color !default;

$ui-button-secondary-color: $grey-600 !default;
$ui-button-secondary-border-color: $grey-600 !default;
$ui-button-secondary-focus-color: $white !default;

$ui-button-tertiary-color: $blurple-500 !default;
$ui-button-tertiary-border-color: $blurple-500 !default;

$primary-text-color: $black !default;
$darker-text-color: $classic-base-color !default;
$highlight-text-color: $ui-highlight-color !default;
$dark-text-color: #444b5d;
$action-button-color: #606984;

$inverted-text-color: $black !default;
$lighter-text-color: $classic-base-color !default;
$light-text-color: #444b5d;

// Newly added colors
$account-background-color: $white !default;

// Invert darkened and lightened colors
@function darken($color, $amount) {
  @return hsl(hue($color), saturation($color), lightness($color) + $amount);
}

@function lighten($color, $amount) {
  @return hsl(hue($color), saturation($color), lightness($color) - $amount);
}

$emojis-requiring-inversion: 'chains';

body {
  --dropdown-border-color: #d9e1e8;
  --dropdown-background-color: #fff;
  --modal-border-color: #d9e1e8;
  --modal-background-color: var(--background-color-tint);
  --background-border-color: #d9e1e8;
  --background-color: #fff;
  --background-color-tint: rgba(255, 255, 255, 80%);
  --background-filter: blur(10px);
}
